<template>
  <div class="card card-custom mt-4">
    <div class="card-body p-0 pt-4 pb-4">
      <b-tabs>
        <b-tab
          v-for="canal in canais_disponiveis"
          :key="canal.value"
          :title="canal.text"
          class="p-4"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h3>Configurações das Mensagens</h3>
            <div class="botoes-container">
              <b-button
                variant="success mr-2"
                @click="salvarConfiguracaoAgenda"
              >
                Salvar Alterações
              </b-button>
              <b-button
                class="btn-danger"
                variant="primary"
                @click="descartarAlteracoes"
                >Descartar</b-button
              >
            </div>
          </div>

          <br />

          <b-alert variant="light" outline show class="w-100">
            <div class="row">
              <div class="col-12">Variáveis</div>
              <div class="col" v-html="`{{1}}: Nome`"></div>
              <div class="col" v-html="`{{2}}: Data`"></div>
              <div class="col" v-html="`{{3}}: Horário: Hora Marcada`"></div>
              <div class="col" v-html="`{{4}}: Prestador`"></div>
              <div class="col" v-html="`{{5}}: Procedimento`"></div>
              <div
                class="col"
                v-html="`{{6}}: Horário: Ordem de Chegada`"
              ></div>
            </div>
          </b-alert>

          <h5>Mensagens de Confirmação</h5>
          <div class="row">
            <div class="col-6 form-group w-100">
              <label for="aprovadoHorarioMarcado"
                >Confirmado (Hora Marcada)</label
              >
              <b-form-textarea
                v-model="mensagens.aprovadoHorarioMarcado.mar_texto"
                type="text"
                class="form-control"
                id="aprovadoHorarioMarcado"
                rows="7"
                no-resize
              />
            </div>

            <div class="col-6 form-group w-100">
              <label for="aprovadoOrdemChegada"
                >Confirmado (Ordem de Chegada)</label
              >
              <b-form-textarea
                v-model="mensagens.aprovadoOrdemChegada.mar_texto"
                type="text"
                class="form-control"
                id="aprovadoOrdemChegada"
                rows="7"
                no-resize
              />
            </div>
          </div>

          <hr />
          <h5>Mensagens de Cancelado</h5>
          <div class="row">
            <div class="col-3 form-group w-100"></div>
            <div class="col-6 form-group w-100">
              <label for="cancelado">Padrão</label>
              <b-form-textarea
                v-model="mensagens.cancelado.mar_texto"
                type="text"
                class="form-control"
                id="cancelado"
                rows="7"
                no-resize
              />
            </div>
          </div>

          <hr />
          <h5>Mensagens de Recusado</h5>
          <div class="row">
            <div class="col-3 form-group w-100"></div>
            <div class="col-6 form-group w-100">
              <label for="recusado">Padrão</label>
              <b-form-textarea
                type="text"
                v-model="mensagens.recusado.mar_texto"
                class="form-control"
                id="recusado"
                rows="7"
                no-resize
              />
            </div>
          </div>

          <hr />
          <h5>Mensagens de Tratamento de Erro</h5>
          <div class="row">
            <div class="col-3 form-group w-100"></div>
            <div class="col-6 form-group w-100">
              <label for="erroAprovado">Para Sincronia de Aprovação</label>
              <b-form-textarea
                type="text"
                v-model="mensagens.erroAprovado.mar_texto"
                class="form-control"
                id="erroAprovado"
                rows="7"
                no-resize
              />
            </div>
          </div> </b-tab
      ></b-tabs>
    </div>
  </div>
</template>

<script>
import API_LINKS from "../../automacao/api.links";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      canais_possiveis: [
        { value: "AGENTE_SITE_WIDGET", text: "Widget" },
        { value: "WHATSAPP_OFICIAL", text: "WhatsApp (Oficial)" },
        { value: "INSTAGRAM", text: "Instagram" }
      ],
      canais_disponiveis: [{ value: "PRINCIPAL", text: "Principal" }],
      mensagens: {
        aprovadoHorarioMarcado: {
          mar_texto: ""
        },
        recusado: {
          mar_texto: ""
        },
        cancelado: {
          mar_texto: ""
        },
        aprovadoOrdemChegada: {
          mar_texto: ""
        },
        erroAprovado: {
          mar_texto: ""
        }
      },
      mensagensCopia: {}
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agende" },
      {
        title: "Configurações",
        route: "/painel/agende/configuracoes"
      }
    ]);
    this.pegarMensagens();
  },

  methods: {
    descartarAlteracoes() {
      if (this.houveAlgumaMudanca) {
        Swal.fire({
          title: "Descartar Alterações?",
          text: "Você tem certeza que deseja descartar as alterações?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, descartar!"
        }).then((result) => {
          if (result.value) {
            this.menu = this.menuConfigsIniciais;
          }
        });
      }
    },

    salvarConfiguracaoAgenda() {
      Swal.fire({
        title: "Salvar Alterações?",
        text: "Você tem certeza que deseja salvar as alterações?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, salvar!"
      }).then((result) => {
        if (result.value) {
          ApiService.post(API_LINKS.salvarMensagemAgenda, {
            mensagens: this.mensagens
          }).then(() => {
            Swal.fire(
              "Salvo!",
              "As mensagens foram salvas com sucesso.",
              "success"
            );
          });
        }
      });
    },

    async pegarMensagens() {
      let { data } = await ApiService.get(API_LINKS.listaMensagensAgenda);
      this.mensagens = data.data.mensagens;
      this.mensagensCopia = { ...this.mensagens };
    }
  },

  computed: {
    houveAlgumaMudanca() {
      return (
        JSON.stringify(this.mensagens) != JSON.stringify(this.mensagensCopia)
      );
    },

    modalSize() {
      if (window.screen.width > 900) return "900";
      return "400";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .p-chips .p-chips-multiple-container {
  width: 100% !important;
}
::v-deep .p-chips-multiple-container {
  flex-wrap: wrap !important;
  gap: 0.5rem !important;
}

.modal-configuracao-menu {
  padding: 24px !important;

  .header-menus-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h3 {
      margin: 0;
    }
    .botoes-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    /* align-items: center; */
    min-height: 100% !important;
  }

  .flexer-col {
    /* flex: 4; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;

    /* only first child
     */
    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
